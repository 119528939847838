import React from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import './scss/app.scss';

import { BsPinMapFill, BsEnvelope } from 'react-icons/bs';
import { FiGithub, FiMap } from 'react-icons/fi';
import { HiCode } from 'react-icons/hi';
import { TiWeatherSunny } from 'react-icons/ti';
import { BiLinkExternal } from 'react-icons/bi';
import { RiMacbookLine } from 'react-icons/ri';
import { FaArrowDownLong } from 'react-icons/fa6';
import { FaBitcoin, FaPizzaSlice, FaLinkedin } from 'react-icons/fa';
import heroImg from './assets/img/img-1.webp';
import laptop from './assets/img/img-2.webp';
import project_1 from './assets/img/img-3.webp';
import project_2 from './assets/img/img-4.webp';
import project_3 from './assets/img/img-5.webp';

function App() {
  return (
    <div className="wrapper">
      <Header />
      <main className="main">
        <section id="home" className="sect-1">
          <div className="container">
            <div className="sect-1__wrap">
              <div className="sect-1__l">
                <h1 className="sect-1__header">
                  Front-End
                  <br /> React Developer{' '}
                  <span>
                    <HiCode />
                  </span>
                </h1>
                <p className="sect-1__text">
                  Hello, I'm Nikita. A Front-end React Developer
                  <br /> based in Kerpen, Germany. &nbsp;
                  <BsPinMapFill />
                </p>
                <a
                  href="https://www.linkedin.com/in/nikita-pankov/"
                  target="_blank"
                  className="sect-1__contact">
                  <FaLinkedin />
                </a>
                <a href="https://github.com/nik191010" target="_blank" className="sect-1__contact">
                  <FiGithub />
                </a>
              </div>
              <div className="sect-1__r">
                <div
                  className="sect-1__pic"
                  style={{
                    backgroundImage: `url(${heroImg})`,
                  }}></div>
              </div>
            </div>
            <div className="sect-1__stack tech-stack">
              <p className="tech-stack__text">Tech Stack</p>
              <div className="tech-stack__wrap">
                <ul className="tech-stack__list">
                  <li className="tech-stack__item">
                    <img
                      src="https://skillicons.dev/icons?i=html,css,js,react,scss"
                      alt="alt"
                      loading="lazy"
                      className="tech-stack__img"
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section id="about" className="sect-2">
          <div className="container">
            <div className="sect-2__wrap">
              <div className="sect-2__thumb">
                <img src={laptop} loading="lazy" alt="alt" className="sect-2__pic" />
              </div>
              <div className="sect-2__desc">
                <h2 className="sect-2__title">About me</h2>
                <p className="sect-2__subtitle">
                  An enthusiastic Front-end Developer based in Kerpen, Germany. &nbsp;
                  <BsPinMapFill />
                </p>
                <p className="sect-2__text">
                  Hello oder Hallo! Ich bin Nikita und wohne in Deutschland. Ich möchte diese
                  Sprache gut lernen und lerne die Sprache jetzt alleine. Entschuldigung, ich
                  spreche immer noch ein wenig Deutsch. I like developing things that are pleasing
                  to the eye and which help people in their daily life. I thrive on problem-solving
                  and have a knack for turning complex concepts into user-friendly solutions.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section id="projects" className="sect-3">
          <div className="container">
            <div className="sect-3__wrap">
              <h2 className="sect-3__title">Projects</h2>
              <p className="sect-3__subtitle">
                You can check out my projects here
                <RiMacbookLine />
              </p>
              <div className="sect-3__projects projects">
                <div className="projects__item">
                  <div className="projects__thumb">
                    <img
                      src={project_1}
                      loading="lazy"
                      alt="Nikita,front-end developer, react weather project"
                      className="projects__pic"
                    />
                  </div>
                  <div className="projects__wrap">
                    <h3 className="projects__title">
                      Weather Forecast <TiWeatherSunny />
                    </h3>
                    <p className="projects__text">
                      A weather forecast is an application which allows users to be aware of their
                      local weather conditions. The app automatically detects users' location and
                      shows the weather in their region,also provides an interface for searching
                      cities, switching between the imperial and metric systems(Celsius to
                      Fahrenheit).
                    </p>
                    <div className="projects__stack">
                      <p className="projects__stack-item">React</p>
                      <p className="projects__stack-item">SCSS</p>
                    </div>
                    <div className="projects__links">
                      <a
                        href="https://github.com/nik191010/react-weather"
                        target="_blank"
                        className="projects__link">
                        Code
                        <FiGithub />
                      </a>
                      <a
                        href="https://react-weather-jet-phi.vercel.app/"
                        target="_blank"
                        className="projects__link">
                        Live Demo
                        <BiLinkExternal />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="projects__item">
                  <div className="projects__thumb">
                    <img
                      src={project_2}
                      loading="lazy"
                      alt="Nikita,front-end developer, react crypto tracker project"
                      className="projects__pic"
                    />
                  </div>
                  <div className="projects__wrap">
                    <h3 className="projects__title">
                      Coin Price Tracker <FaBitcoin />
                    </h3>
                    <p className="projects__text">
                      Coin Price Tracker is a crypto app that allows users to search for information
                      about various cryptocurrencies, checking out rates, convert cryptocurrencies
                      in their national currencies.
                    </p>
                    <div className="projects__stack">
                      <p className="projects__stack-item">React</p>
                      <p className="projects__stack-item">SCSS</p>
                    </div>
                    <div className="projects__links">
                      <a
                        href="https://github.com/nik191010/react-crypto"
                        target="_blank"
                        className="projects__link">
                        Code
                        <FiGithub />
                      </a>
                      <a
                        href="https://react-crypto-alpha.vercel.app"
                        target="_blank"
                        className="projects__link">
                        Live Demo
                        <BiLinkExternal />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="projects__item">
                  <div className="projects__thumb">
                    <img
                      src={project_3}
                      loading="lazy"
                      alt="Nikita,front-end developer, react pizza project"
                      className="projects__pic"
                    />
                  </div>
                  <div className="projects__wrap">
                    <h3 className="projects__title">
                      Pizza App
                      <FaPizzaSlice />
                    </h3>
                    <p className="projects__text">
                      Pizza app lets users to choose different pizzas and their parameters(sizes
                      etc), search for pizzas and add their purchases to the cart.
                    </p>
                    <div className="projects__stack">
                      <p className="projects__stack-item">React</p>
                      <p className="projects__stack-item">SCSS</p>
                    </div>
                    <div className="projects__links">
                      <a
                        href="https://github.com/nik191010/react-pizza"
                        target="_blank"
                        className="projects__link">
                        Code
                        <FiGithub />
                      </a>
                      <a
                        href="https://react-pizza-rose.vercel.app"
                        target="_blank"
                        className="projects__link">
                        Live Demo
                        <BiLinkExternal />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="contact" className="sect-4">
          <div className="container">
            <h2 className="sect-4__title">Contact</h2>
            <p className="sect-4__subtitle">
              You can contact me here <FaArrowDownLong />
            </p>
            <div className="sect-4__wrap">
              <div className="sect-4__item">
                <span className="sect-4__icon">
                  <FiMap />
                </span>
                <div className="sect-4__contact-info">
                  <h3 className="sect-4__contact-head">Location</h3>
                  <p className="sect-4__contact-text">Kerpen, Germany</p>
                </div>
              </div>
              <div className="sect-4__item">
                <span className="sect-4__icon">
                  <BsEnvelope />
                </span>
                <div className="sect-4__contact-info">
                  <h3 className="sect-4__contact-head">Mail</h3>
                  <a href="mailto:191010.n@gmail.com" className="sect-4__contact-text">
                    191010.n@gmail.com
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
}

export default App;
